import React, { Component } from "react"
import { Link } from "gatsby"

import logo from "../../content/assets/logo.png"

class Navbar extends Component {
  state = { open: false }

  handleModal = () => {
    this.setState({ open: !this.state.open })
  }

  renderDesktopNav = pathname => {
    let justifyEnd
    let hideLogo

    if (pathname === `/`) {
      justifyEnd = true
      hideLogo = true
    }

    return (
      <div
        className={`mt-12 flex ${
          !justifyEnd ? "justify-between" : "justify-end"
        }`}
      >
        <div className={`flex ${hideLogo ? "hidden" : null}`}>
          <Link className="flex-shrink-0" to="/">
            <img className="w-16" src={logo} alt="justin liwag logo"></img>
          </Link>
        </div>
        <div>
          <ul className="block text-sm font-semibold tracking-wide text-gray-700 md:flex">
            <Link className="ml-6" to="/blog">
              <li>WRITING</li>
              <span className="block -mt-2 border-b-4 border-blue-200"></span>
            </Link>
            <a
              href="https://www.notion.so/justinliwag/Public-External-Brain-93a46459b77c4a6fa5d23bbfa1e97957"
              className="ml-6"
            >
              <li>EXTERNAL BRAIN</li>
              <span className="block -mt-2 border-b-4 border-blue-200"></span>
            </a>
            <a
              href="https://www.notion.so/Read-ing-List-96d87fa62d584af2bf68ea5bc9c6e215"
              className="ml-6"
            >
              <li>READ[ING]</li>
              <span className="block -mt-2 border-b-4 border-blue-200"></span>
            </a>
            <Link to="/contact" className="ml-6">
              <li>CONTACT</li>
              <span className="block -mt-2 border-b-4 border-blue-200"></span>
            </Link>
          </ul>
        </div>
      </div>
    )
  }

  renderMobileNav = pathname => {
    let justifyEnd
    let hideLogo

    if (pathname === `/`) {
      justifyEnd = true
      hideLogo = true
    }

    return (
      <div
        className={`mt-12 flex ${
          !justifyEnd ? "justify-between" : "justify-end"
        }`}
      >
        <div className={`flex ${hideLogo ? "hidden" : null}`}>
          <Link className="flex-shrink-0" to="/">
            <img className="w-16" src={logo} alt="justin liwag logo"></img>
          </Link>
        </div>
        <div>
          <div className="text-right" onClick={this.handleModal}>
            {this.state.open === false ? (
              <svg
                className="inline"
                width="32"
                height="32"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 5C3 4.73478 3.10536 4.48043 3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H16C16.2652 4 16.5196 4.10536 16.7071 4.29289C16.8946 4.48043 17 4.73478 17 5C17 5.26522 16.8946 5.51957 16.7071 5.70711C16.5196 5.89464 16.2652 6 16 6H4C3.73478 6 3.48043 5.89464 3.29289 5.70711C3.10536 5.51957 3 5.26522 3 5ZM3 10C3 9.73478 3.10536 9.48043 3.29289 9.29289C3.48043 9.10536 3.73478 9 4 9H16C16.2652 9 16.5196 9.10536 16.7071 9.29289C16.8946 9.48043 17 9.73478 17 10C17 10.2652 16.8946 10.5196 16.7071 10.7071C16.5196 10.8946 16.2652 11 16 11H4C3.73478 11 3.48043 10.8946 3.29289 10.7071C3.10536 10.5196 3 10.2652 3 10ZM9 15C9 14.7348 9.10536 14.4804 9.29289 14.2929C9.48043 14.1054 9.73478 14 10 14H16C16.2652 14 16.5196 14.1054 16.7071 14.2929C16.8946 14.4804 17 14.7348 17 15C17 15.2652 16.8946 15.5196 16.7071 15.7071C16.5196 15.8946 16.2652 16 16 16H10C9.73478 16 9.48043 15.8946 9.29289 15.7071C9.10536 15.5196 9 15.2652 9 15Z"
                  fill="#2D3748"
                />
              </svg>
            ) : (
              <svg
                className="inline"
                width="32"
                height="32"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.29279 4.29299C4.48031 4.10552 4.73462 4.0002 4.99979 4.0002C5.26495 4.0002 5.51926 4.10552 5.70679 4.29299L9.99979 8.58599L14.2928 4.29299C14.385 4.19748 14.4954 4.1213 14.6174 4.06889C14.7394 4.01648 14.8706 3.98889 15.0034 3.98774C15.1362 3.98659 15.2678 4.01189 15.3907 4.06217C15.5136 4.11245 15.6253 4.1867 15.7192 4.28059C15.8131 4.37449 15.8873 4.48614 15.9376 4.60904C15.9879 4.73193 16.0132 4.86361 16.012 4.99639C16.0109 5.12917 15.9833 5.26039 15.9309 5.38239C15.8785 5.5044 15.8023 5.61474 15.7068 5.70699L11.4138 9.99999L15.7068 14.293C15.8889 14.4816 15.9897 14.7342 15.9875 14.9964C15.9852 15.2586 15.88 15.5094 15.6946 15.6948C15.5092 15.8802 15.2584 15.9854 14.9962 15.9877C14.734 15.9899 14.4814 15.8891 14.2928 15.707L9.99979 11.414L5.70679 15.707C5.51818 15.8891 5.26558 15.9899 5.00339 15.9877C4.74119 15.9854 4.49038 15.8802 4.30497 15.6948C4.11956 15.5094 4.01439 15.2586 4.01211 14.9964C4.00983 14.7342 4.11063 14.4816 4.29279 14.293L8.58579 9.99999L4.29279 5.70699C4.10532 5.51946 4 5.26515 4 4.99999C4 4.73483 4.10532 4.48052 4.29279 4.29299V4.29299Z"
                  fill="#2D3748"
                />
              </svg>
            )}
          </div>
          {this.state.open === true ? (
            <div className="mt-8">
              <Link
                to="/blog"
                className="block mt-6 text-lg font-semibold tracking-wide text-right"
              >
                Writing
              </Link>
              <a
                href="https://www.notion.so/justinliwag/Public-External-Brain-93a46459b77c4a6fa5d23bbfa1e97957"
                className="block mt-6 text-lg font-semibold tracking-wide text-right"
              >
                External Brain
              </a>
              <a
                href="https://www.notion.so/Read-ing-List-96d87fa62d584af2bf68ea5bc9c6e215"
                className="block mt-6 text-lg font-semibold tracking-wide text-right"
              >
                Read[ing]
              </a>
              <Link
                to="/contact"
                className="block mt-6 text-lg font-semibold tracking-wide text-right"
              >
                Contact
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  render() {
    const { pathname } = this.props.location

    return (
      <>
        <div className="hidden md:block">{this.renderDesktopNav(pathname)}</div>
        <div className="block md:hidden">{this.renderMobileNav(pathname)}</div>
      </>
    )
  }
}

export default Navbar
