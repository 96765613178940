import React from "react"
import Navbar from "./Navbar"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div className="mx-8 font-gothic max-w-2xl lg:max-w-4xl min-h-screen flex flex-col md:mx-auto text-gray-800 leading-relaxed">
        <div className="flex-1">
          {/* <header>{header}</header> */}
          <Navbar location={this.props.location} />
          <main>{children}</main>
        </div>
        <footer className="mx-auto mt-32 mb-8">
          ©{new Date().getFullYear()}+ Justin Liwag
        </footer>
      </div>
    )
  }
}

export default Layout
